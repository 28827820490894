// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "@fortawesome/fontawesome-free/css/all.css";
import "activeadmin_addons";
import "arctic_admin";

window.onload = function() {
  if ($('.admin_device_configs').length != 0)  {
    $('.blank_slate a').hide();
  }

  dailyReportsWrapper();
  eReceiptWrapper();
  deviceConfigAutocomplete();
  taxOfficeAutocomplete();

  if ($('#edit_user').length == 0 && $("#new_user").length == 0) return;

  subscribeInputs();
  hideInputs();

  $('.has_many_add').on('click', function() {
    setTimeout(subscribeInputs, 50);
    setTimeout(hideInputs, 50);
  })
};

$(function () {
  let clear_btn = $('.clear_filters_btn');
  let submit_btn = $('.filter_form input[type="submit"]');

  clear_btn.on('click', function() {
    submit_btn.attr("disabled", true);
  });
  submit_btn.on('click', function() {
    clear_btn.removeClass('clear_filters_btn');
    clear_btn.removeAttr('href');
  });
});

function subscribeInputs() {
  $('.role_name').on('select2:select', function(e) {
    var selectInput = $(e.target);
    var selectId = selectInput.attr("id").split('_').slice(-2, -1)[0];
    var groupInput = $(`#user_kept_roles_attributes_${selectId}_group_id_input`);

    if (e.params.data.text == "super_admin") {
      groupInput.hide();
    }
    else {
      groupInput.show();
    }
  })

  $('input:checkbox').on('change', function(e) {
    var selectId = $(e.target).attr("id").split('_').slice(-3, -2)[0];
    var groupInput = $(`#user_kept_roles_attributes_${selectId}_group_id_input`);
    var nameInput = $(`#user_kept_roles_attributes_${selectId}_name_input`);

    if ($(e.target).prop("checked")) {
      groupInput.addClass("destroyed");
      nameInput.addClass("destroyed");
    }
    else {
      groupInput.removeClass("destroyed");
      nameInput.removeClass("destroyed");
    }
  })
}

function hideInputs() {
  var lockSuperAdmin = true;
  $('.role_name').each(function() {
    $(this).select2();
    var selectId = $(this).attr("id").split('_').slice(-2, -1)[0];
    var groupInput = $(`#user_kept_roles_attributes_${selectId}_group_id_input`);
    var deleteInput = $(`#user_kept_roles_attributes_${selectId}__destroy_input`);

    var val = $(this).select2('data')[0].text;

    if ((val == "super_admin") || val.length == 0) {
      groupInput.hide();

      if (val == "super_admin" && $(this).hasClass("persisted")) {
        deleteInput.hide();
        $(this).select2({disabled: lockSuperAdmin});
        lockSuperAdmin = false;
      }
    }
  })
}

function dailyReportsWrapper() {
  let btn = $('.btn-wrapper');
  let btnWrapper = $('#daily-reports-wrapper');
  let btnUnwrapper = $('#daily-reports-unwrapper');
  let jsonContainer = $('pre.container-wrapped');

  btn.on('click', () => {
    btnWrapper.toggle();
    btnUnwrapper.toggle();

    jsonContainer.toggleClass("container-wrapped container-unwrapped");
  });
}

function eReceiptWrapper() {
  let btnJwe = $('.btn-jwe-wrapper');
  let btnJws = $('.btn-jws-wrapper');
  let btnJweWrapper = $('#e-receipt-jwe-wrapper');
  let btnJweUnwrapper = $('#e-receipt-jwe-unwrapper');
  let btnJwsWrapper = $('#e-receipt-jws-wrapper');
  let btnJwsUnwrapper = $('#e-receipt-jws-unwrapper');
  let jweContainer = $('pre.container-wrapped');
  let jwsContainer = $('p.container-wrapped');

  btnJwe.on('click', () => {
    btnJweWrapper.toggle();
    btnJweUnwrapper.toggle();

    jweContainer.toggleClass("container-wrapped container-unwrapped");
  });

  btnJws.on('click', () => {
    btnJwsWrapper.toggle();
    btnJwsUnwrapper.toggle();

    jwsContainer.toggleClass("container-wrapped container-unwrapped");
  });
}

function deviceConfigAutocomplete() {
  const specialValuesKeys = ["currency_exchange", "white_list_devices", "tax_rates", "tax_offices"];

  let templateInput = $(`#device-config-template`);
  let keyInput = $(`#device-config-key`);
  let valueInput = $(`#device-config-value`);

  templateInput.on("change", () => {
    let templateInputValue = templateInput.val();

    if (templateInputValue !== ""){
      let formattedKeyInputValue = $(keyInput).data(templateInputValue);
      let formattedValueInputValue = $(valueInput).data(templateInputValue);

      keyInput.val(formattedKeyInputValue);
      specialValuesKeys.includes(formattedKeyInputValue) ? valueInput.val(JSON.stringify(formattedValueInputValue)) : valueInput.val(formattedValueInputValue);
    }
  });

  keyInput.on("change", () => {
    templateInput.val("").trigger("change");
  });
}

function taxOfficeAutocomplete() {
  let taxOffice = $(`#tax-office`);
  let taxOfficeVoivodeship = $(`#tax-office-voivodeship`);
  let taxOfficeCode = $(`#tax-office-code`);

  taxOfficeVoivodeship.on("change", () => {
    let taxOfficeValue = taxOfficeVoivodeship.val();
    let taxOfficeArray = []
    taxOfficeArray = taxOfficeCode.data()[taxOfficeValue]
    taxOfficeCode.find("option:not(:first)").remove();
    let newOptions = taxOfficeArray;

    newOptions.forEach(option => {
      const newOption = $("<option>").val(option['code']).text(option['name']);
      taxOfficeCode.append(newOption);
    });
  });

  taxOfficeCode.on("change", () => {
    const selectedIndex = taxOfficeCode[0].selectedIndex;
    if (selectedIndex !== -1) {
      const selectedOptionText = taxOfficeCode[0].options[selectedIndex].textContent;
      taxOffice.val(selectedOptionText);
    }
  });
}
